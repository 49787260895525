'use strict';

let retryTimes = 200;

document.addEventListener('DOMContentLoaded', function() {
	attachBannerScroll();
	initTimer();
	showTimer();
	successListener();
	checkBlockedForm();
});

//Listener for form
const successListener = () => {
	observeForSuccessMessage();
};

function observeForSuccessMessage() {
	var elementToObserve = document.querySelector('.register');

	var observerConfig = {
		childList: true,
		subtree: true,
		attributes: true
	};

	// eslint-disable-next-line no-undef
	var observer = new MutationObserver(function(mutations) {
		mutations.forEach(function(mutation) {
			const mutationTarget = mutation.target;
			const isMessage = mutationTarget.classList.contains('landing-page-form-message');

			if (isMessage) {
				const successMessage = document.querySelector('.landing-page-form-message p').textContent.trim().toLowerCase();
				if (successMessage === 'success! you\'ve been added to the list.') {
					window.dataLayer = window.dataLayer || [];
					window.dataLayer.push({
						'event': 'preregister'
					});
				}
			}
		});

	});

	observer.observe(elementToObserve, observerConfig);
}

//Timer
const initTimer = () => {
	/* Change this date to your preffered target date! (only the month day year part)*/
	/* Timezone so we get zone specific  countdown */
	let timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

	/* if browser does not support timezone we default to bucharest time */
	if (timeZone === undefined) {
		timeZone = 'Europe/Bucharest';
	}
	const targetDate = dateWithTimeZone(timeZone, 2022, 9, 22, 0, 0, 0);
	const endDate = dateWithTimeZone(timeZone, 2022, 9, 24, 0, 0, 0);

	window.setInterval(function() {
		/*  Where we check if 'now' is greater than the target date */
		const date = Date.now();

		if (date > targetDate && date < endDate) {
			clearInterval();
			updateCountDownToLiveState();
			return;
		}

		if (date > endDate) {
			removeCountDown();
			return;
		}
		const millis = targetDate - date;
		const millisObject = convertMillis(millis);
		const daysContainer = document.querySelector('.hero__countdown-timer-item--days .hero__countdown-timer-value');
		const hoursContainer = document.querySelector('.hero__countdown-timer-item--hours .hero__countdown-timer-value');
		const minutesContainer = document.querySelector('.hero__countdown-timer-item--minutes .hero__countdown-timer-value');
		const secondsContainer = document.querySelector('.hero__countdown-timer-item--seconds .hero__countdown-timer-value');

		/* Here we set values */
		daysContainer.innerHTML = millisObject.d;
		hoursContainer.innerHTML = millisObject.h;
		minutesContainer.innerHTML = millisObject.m;
		secondsContainer.innerHTML = millisObject.s;
	}, 1000);

};
const updateCountDownToLiveState = () => {
	const countDownContainer = document.querySelector('.hero__countdown');
	const countDownTimer = countDownContainer.querySelector('.hero__countdown-timer');
	const countDownText = countDownContainer.querySelector('.hero__countdown-text h3');
	const countDownButton = countDownContainer.querySelector('.hero__countdown-button button');

	countDownTimer.remove();
	countDownText.innerText = 'Join our CLV Revolution';
	countDownButton.innerText = 'Live Now';

	countDownButton.addEventListener('click', function(e){
		e.preventDefault();
	});
};
const removeCountDown = () => {
	const countDownContainer = document.querySelector('.hero__countdown');

	countDownContainer.remove();
};
const dateWithTimeZone = (timeZone, year, month, day, hour, minute, second) => {
	var date = new Date(Date.UTC(year, month - 1, day, hour, minute, second));

	var utcDate = new Date(date.toLocaleString('en-US', { timeZone: 'UTC' }));
	var tzDate = new Date(date.toLocaleString('en-US', { timeZone: timeZone }));
	var offset = utcDate.getTime() - tzDate.getTime();

	date.setTime(date.getTime() + offset);

	return date;
};
const showTimer = () => {
	//We show the timer with a delay so our values get populated and it actually starts counting by the time we show it
	const timerContainer = document.querySelector('.hero__countdown-timer');

	setTimeout(() => {
		timerContainer.classList.add('js--show');
	}, 1000);
};

/* This can add zeroes to the return values below */
function addZero(n) {
	return (n < 10 ? '0' + n : n);
}

function convertMillis(milliseconds, format) {
	var days, hours, minutes, seconds, total_hours, total_minutes, total_seconds;

	total_seconds = parseInt(Math.floor(milliseconds / 1000));
	total_minutes = parseInt(Math.floor(total_seconds / 60));
	total_hours = parseInt(Math.floor(total_minutes / 60));
	days = parseInt(Math.floor(total_hours / 24));

	seconds = parseInt(total_seconds % 60);
	minutes = parseInt(total_minutes % 60);
	hours = parseInt(total_hours % 24);

	switch (format) {

	/* beautify preserve:start */
	case 's':
		return total_seconds;
	case 'm':
		return total_minutes;
	case 'h':
		return total_hours;
	case 'd':
		return days;
	default:
		return { d: addZero(days), h: addZero(hours), m: addZero(minutes), s: addZero(seconds) };

	/* beautify preserve:end */
	}
};

//Scroll To Form
const attachBannerScroll = () => {
	const bannerRegister = document.querySelector('.hero__countdown .button--gradient');

	bannerRegister.addEventListener('click', e => {
		e.preventDefault();
		const target = document.querySelector('.register');

		scrollTo(target);
	});
};

const scrollTo = element => {
	window.scroll({
		behavior: 'auto',
		left: 0,
		top: element.offsetTop
	});
};

/**
 * Check if form is blocked
 */
const checkBlockedForm = () => {
	const moosendForm = document.querySelector('.register__form-container div[data-mooform-id]');
	const hasForm = !!moosendForm.children.length;

	/* Remove adblock message if form isn't blocked */
	if (hasForm) {
		const adblockDetector = document.querySelector('.adblock-detector');

		adblockDetector.remove();
	} else if (retryTimes > 0 ) {
		retryTimes--;
		setTimeout(checkBlockedForm, 100);
	}
};